.create-game-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-game-form-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 30px;
  font-family: "Helvetica", "sans-serif";
}

.create-game-form-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.create-game-form-label {
  font-size: 1.2rem;
  margin-bottom: 5px;
  font-weight: bold;
  color: grey;
}

.create-game-form-input {
  display: flex;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.create-game-form-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #62b966;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.create-game-form-button:hover, .create-game-form-button:active, .create-game-form-button:focus {
  background-color: #3d8b3c !important;
}

.join-game-form-button {
  padding: 10px 20px;
  background-color:#6662b9;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.join-game-form-button:hover, .join-game-form-button:active, .join-game-form-button:focus {
  background-color: #3c3d8b !important;
}

.help-game-form-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #ff4e4e;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.help-game-form-button:hover, .help-game-form-button:active, .help-game-form-button:focus {
  background-color: #bc3b3b !important;
}

.full-screen-box {
  position: fixed;
  top: 10%;
  right: 10%;
  bottom: 5%;
  left: 10%;
  background-color: #ff4e4e;
  scrollbar-width: thin;
  overflow-y: scroll;
  z-index: 1000;
  /* Add other styles as needed */
}

.full-screen-box-content {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 35px;
  padding-right: 35px;
  padding-top: 10px;
}

.accordion-button {
  background-color: #ff4e4e !important;
  color: white;
}


.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: white;
}

.accordion-button.collapsed {
  background: blue
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  z-index: 9999;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: white;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  color: blue;
}

/* Accordion menu container */
.accordion-menu {
  width: 100%;
}

/* Accordion menu item */
.accordion-menu-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

/* Accordion menu header */
.accordion-menu-header {
  cursor: pointer;
  padding: 0px 15px 0px 15px;
  margin: 0px 15px 0px 15px;
  background-color: #ff4e4e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Accordion menu content */
.accordion-menu-content {
  padding: 0px 20px 15px 20px;
}
  
/* Accordion menu content */
.accordion-menu-content p {
  margin-top: 0px;
}

.accordion-menu-content b {
  font-weight: bolder;
}

/* Accordion menu image */
.accordion-menu-image {
  margin-left: 10px;
  flex: 0 0 150px;
  max-width: 400px;
}

/* Accordion menu image img */
.accordion-menu-image img {
  max-width: 100%;
  margin-right: 10px;
}

/* Accordion menu text */
.accordion-menu-text {
  flex: 1;
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
}

.accordion-menu-text p {
  white-space: pre-line;
}

/* Clear floats */
.clearfix::after {
  content: '';
  display: table;
  clear: both;
}