.waiting-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
  
  @import url('https://fonts.googleapis.com/css?family=Poppins');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  text-align:center;
}

.flickity-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  height: 20vh;
}
.hand {
  position: relative;
  height: 100px;
}

.copy-text {
  position: relative;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
}

.copy-text text {
  padding: 10px;
  font-size: 10px;
  color: #555;
  border: none;
  outline: none;
}

.copy-text button {
  margin-left: 5px;
}

.card {
  font-size:22px;
  height:100px;
  width:70px;
  border: 3px solid #262722;
  border-radius: 10px;
  background-color: #b3704d;
  background-color: lavender;
  position: absolute;
  top: 0;
  transition: transform 0.2s ease; 
  display: flex;
  justify-content: center;
  align-items: center;
  transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  
  span {
    background-color: #8badc8;
    display:none;
    margin:0 auto;
    width:50px;
    height: 50px;
    border-radius: 25px;
  }
  
  &.card-1 {
    z-index:3;
    background-color: #4caf50;
    margin-left: 20px;
    animation-duration: 6s;
    animation-name: card-1;
    animation-iteration-count: infinite;
  }
  &.card-2 {
    z-index:2;
    background-color: #c55656;
    margin-left:10px;
    animation-duration: 6s;
    animation-name: card-2;
    animation-iteration-count: infinite;
  }
  &.card-3 {
    z-index: 1;
    background-color: #5658c5;
    animation-duration: 6s;
    animation-name: card-3;
    animation-iteration-count: infinite;
  }
}

.notification-alert {
  position: fixed;
  color: white;
  padding: 1rem;
  justify-content: center;
  opacity: 0;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  animation: flyinout 2.5s linear forwards;
}

.notification-alert--success {
  background-color: #62b966;
  color: white;
}

.notification-alert--error {
  background-color: #ee5a4e;
  color: white;
}

@keyframes flyinout {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    bottom: 10px;
  }
  60% {
    opacity: 1;
    bottom: 10px;
  }
  100% {
    opacity: 0;
  }
}

@keyframes card-1 {
  0% {}
  16.66666% {transform: translateX(95px) rotate(15deg)} // slide out
  33.33333%  {transform: translateX(-20px); z-index:1} // to back
  49.99999% {transform: translateX(-20px); z-index:1}
  66.66666% {transform: translateX(-10px); z-index:2} // to middle
  83.33333% {transform: translateX(-10px); z-index:2}
}

@keyframes card-2 {
  0% {}
  16.66666% {transform: translateX(0)}
  33.33333%  {transform: translateX(10px); z-index:3} // to front
  49.99999% {transform: translateX(105px) rotate(15deg)} // slide out
  66.66666% {transform: translateX(-10px); z-index:1} //to back
  83.33333% {transform: translateX(-10px); z-index:1} //stay still
}

@keyframes card-3 {
  0% {z-index: 1} //to back
  16.66666% {transform: translateX(0)}
  33.33333%  {transform: translateX(10px); z-index:2} // to middle
  49.99999% {transform: translateX(10px); z-index:2} //stay still 
  66.66666% {transform: translateX(20px); z-index:3} //to front
  83.33333% {transform: translateX(115px) rotate(15deg);} //slide out
}

  