.card-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .card-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .card-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .card-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .card-selected {
    border: 2px solid red;
  }

  .hover-effect:hover {
    transform: translateY(-15px); /* Apply the desired hover effect, e.g., translateY, scale, etc. */
    /* Add any other styles for the hover effect */
  }

    /* CSS for Your Turn */
  .your-turn {
    font-size: 20px;
    background-color: #28a745; /* Green background color */
    color: #fff; /* White text color */
    height: auto;
    border-radius: 10px;
  }

  /* CSS for Opponent's Turn */
  .opponent-turn {
    font-size: 20px;
    background-color: #dc3545; /* Red background color */
    color: #fff; /* White text color */
    border-radius: 10px;
  }

  /* Add the score-animation class for the animation */
  .score-animation {
    animation-name: scoreAnimation; /* Name of the keyframe animation */
    animation-duration: 1.5s; /* Duration of the animation */
    animation-timing-function: ease-in-out; /* Timing function for the animation */
    animation-fill-mode: forwards; /* Keep the final state of the animation */
  }

  @keyframes scoreAnimation {
    0% {
      transform: scale(1); /* Starting scale */
      opacity: 1; /* Starting opacity */
    }
    50% {
      transform: scale(1.20); /* Scale up to 1.5x midway through the animation */
      color: green;
      opacity: 1; /* Fade out midway through the animation */
    }
    100% {
      transform: scale(1); /* Scale back to 1x at the end of the animation */
      opacity: 1; /* Fade in at the end of the animation */
    }
  }

  /* Default style without zoom */
.game-container {
  position: relative;
  background-color: rgb(54, 147, 54);
  /* Your other styles */
}

@media (min-width: 0px) { /* small screens (md) and above */
  .game-content {
    transform: scale(0.6);
  }
}

@media (min-width: 576px) { /* Medium screens (md) and above */
  .game-content {
    transform: scale(0.7);
  }
}

@media (min-width: 1152px) { /* Big screens (md) and above */
  .game-content {
    transform: scale(0.8);
  }
}

.rules-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure overlay is above other content */
}

.rules-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  position: relative;
}

.rules-close-button {
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.rules-h1 {
  margin: 0;
}

.rules-main {
  padding: 20px;
}

.rules-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.rules-h2 {
  color: #007bff;
}

.rules-ul {
  list-style-type: disc;
  margin-left: 20px;
}